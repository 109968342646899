import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';

import { useCampaignPage } from '@/context/CampaignPage';
import { formatCurrency } from '@/lib/formatters';

export const EntriesWrapper = ({ raffleDetails }) => {
  const router = useRouter();
  const { campaignId } = useCampaignPage();
  const isMultiEntryPackages = raffleDetails?.entryPackages?.length > 1;

  return (
    <div className="w-full bg-white shadow-lg border border-gray-300 rounded-lg p-6  md:p-8">
      <h2 className="text-lg md:text-2xl font-medium">Enter to Win</h2>
      <div
        className={` mt-6 w-full grid gap-2 md:gap-3 ${isMultiEntryPackages ? 'md:grid-cols-2' : 'grid-cols-1'}`}
      >
        {(raffleDetails?.entryPackages ?? []).map((detail, i) => (
          <RaffleEntryComp
            isDoubleRow
            isDisabled={raffleDetails.status === 'closed'}
            key={i}
            onClick={() =>
              router.push(
                `/c/${campaignId}/raffle-checkout?raffleId=${raffleDetails.id}&entries=${detail.entries}`
              )
            }
            {...detail}
          />
        ))}
      </div>
    </div>
  );
};

const RaffleEntryComp = ({ isDoubleRow = false, entries, amount, isDisabled = false, onClick }) => (
  <button
    type="button"
    className={`flex justify-between items-center md:text-lg  text-white rounded-lg py-4 px-4 md:py-8 md:px-8 w-full ${isDoubleRow ? 'md:flex-col md:gap-1.5' : ''} ${isDisabled ? '!bg-gray-400 pointer-events-none cursor-none' : '!bg-theme-primary'} hover:opacity-75`}
    onClick={onClick}
  >
    <p className="text-left font-medium w-full whitespace-nowrap">{entries} Entries</p>
    <div
      className={`flex items-center w-full justify-end gap-3 ${isDoubleRow ? 'md:justify-between' : ''}`}
    >
      <p>{formatCurrency(amount)}</p>
      <FontAwesomeIcon icon={faCirclePlus} className="shrink-0 text-2xl" />
    </div>
  </button>
);

RaffleEntryComp.propTypes = {
  entries: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  className: PropTypes.string,
  isDoubleRow: PropTypes.boolean,
  isDisabled: PropTypes.Boolean,
  onClick: PropTypes.func.isRequired,
};

RaffleEntryComp.defaultProps = {
  entries: 0,
  amount: 0,
};

export default RaffleEntryComp;

import { useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useResizeObserverRef } from 'rooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faHourglassEnd,
  faPiggyBank,
} from '@fortawesome/pro-regular-svg-icons';
import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import { formatCurrency } from '../../lib/formatters';
import { serializeToJSX } from 'lib/serializeTiptap';
import big from 'big.js';

import cx from 'lib/cx';

import { useCampaignPage } from '@/context/CampaignPage';
import Pill from 'components/common/Pill';
import Button from 'components/common/Button';
import RaffleEntryComp from './CampaignPageRaffleEntries';

const RaffleTextWrapper = ({ raffleWidget, raffleDetails }) => {
  const router = useRouter();
  const { campaignId } = useCampaignPage();
  const isMultiEntryPackages = raffleDetails?.entryPackages?.length > 1;

  let estimatedWinnings = 0;
  if (raffleWidget.config?.isDisplayEstimatedWinnings)
    estimatedWinnings = big(raffleDetails.amount ?? 0)
      .div(2)
      .round(2);

  return (
    <div className="w-full ">
      <div className="flex items-center justify-between mb-8">
        {raffleDetails.status === 'open' ? <ActiveStatus /> : <EndedStatus />}
        {raffleWidget.config?.isDisplayEstimatedWinnings && (
          <div className="flex items-center text-gray-600 gap-2 border border-gray-400 py-2 px-3 rounded-xl text-sm">
            <FontAwesomeIcon icon={faPiggyBank} />
            <span className="font-medium text-gray-800">
              {formatCurrency(estimatedWinnings)}
            </span>{' '}
            est. winnings
          </div>
        )}
      </div>

      <h2 className={'text-2xl lg:text-4xl font-medium leading-tight'}>{raffleWidget.title}</h2>
      <Description value={raffleDetails?.description} />

      {raffleWidget.config?.image && (
        <div
          className={`mt-8 w-full grid gap-2 md:gap-3 ${isMultiEntryPackages ? 'md:grid-cols-2' : 'grid-cols-1'}`}
        >
          {(raffleDetails?.entryPackages ?? []).map((detail) => (
            <RaffleEntryComp
              key={detail.id}
              {...detail}
              isDisabled={raffleDetails.status === 'closed'}
              onClick={() =>
                router.push(
                  `/c/${campaignId}/raffle-checkout?raffleId=${raffleDetails.id}&entries=${detail.entries}`
                )
              }
            />
          ))}
        </div>
      )}
    </div>
  );
};

const ActiveStatus = () => (
  <Pill as="div" padding="md" color="green">
    <div className="flex items-center text-sm gap-1.5">
      <FontAwesomeIcon icon={faBolt} />
      <span>Active</span>
    </div>
  </Pill>
);

const EndedStatus = () => (
  <Pill as="div" padding="md" color="gray-800">
    <div className="flex items-center text-sm gap-1.5">
      <FontAwesomeIcon icon={faHourglassEnd} />
      <span>Ended</span>
    </div>
  </Pill>
);

const Description = ({ value }) => {
  const [isDescriptionExpandable, setIsDescriptionExpandable] = useState(false);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const [descriptionElem] = useResizeObserverRef((e) =>
    setIsDescriptionExpandable(e[0].target.scrollHeight > e[0].target.clientHeight)
  );

  return (
    <div className="mt-2 text-gray-600">
      <div
        ref={descriptionElem}
        className={cx('leading-relaxed text-base lg:text-lg rich-text', {
          'line-clamp-2': !isDescriptionExpanded,
        })}
      >
        {serializeToJSX(value)}
      </div>

      {isDescriptionExpandable && (
        <p className="mt-2">
          <Button
            as="button"
            type="button"
            color="gray-300"
            padding="sm"
            className="mt-4 uppercase font-medium text-xs"
            onClick={() => setIsDescriptionExpanded((prev) => !prev)}
            outline
            radius="full"
          >
            {isDescriptionExpanded ? 'Read less' : 'Read more'}
            <FontAwesomeIcon
              icon={!isDescriptionExpanded ? faChevronDown : faChevronUp}
              className="ml-2"
            />
          </Button>
        </p>
      )}
    </div>
  );
};

RaffleTextWrapper.propTypes = {
  raffleWidget: PropTypes.object.isRequired,
  raffleDetails: PropTypes.object.isRequired,
};

export default RaffleTextWrapper;

import { Node, mergeAttributes } from '@tiptap/core';
import { registerCustomProtocol, reset } from 'linkifyjs';

export interface ButtonProtocolOptions {
  scheme: string;
  optionalSlashes?: boolean;
}

export interface ButtonOptions {
  protocols: Array<ButtonProtocolOptions | string>;
  defaultProtocol: string;
  openOnClick: boolean;
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    button: {
      setButton: (attributes: { href: string; text?: string; class?: string | null }) => ReturnType;
      unsetButton: () => ReturnType;
    };
  }
}

// From DOMPurify
// https://github.com/cure53/DOMPurify/blob/main/src/regexp.js
const ATTR_WHITESPACE = /[\u0000-\u0020\u00A0\u1680\u180E\u2000-\u2029\u205F\u3000]/g; // eslint-disable-line no-control-regex
const IS_ALLOWED_URI =
  /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|sms|cid|xmpp):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i; // eslint-disable-line no-useless-escape

function isAllowedUri(uri: string | undefined) {
  return !uri || uri.replace(ATTR_WHITESPACE, '').match(IS_ALLOWED_URI);
}

/**
 * This extension is built using the first-party link extension as a base.
 * @see https://www.tiptap.dev/api/marks/link
 */
export const ButtonExtension = Node.create<ButtonOptions>({
  name: 'button',
  content: 'text*',
  marks: '',
  group: 'block',
  priority: 50,

  onCreate() {
    this.options.protocols.forEach((protocol) => {
      if (typeof protocol === 'string') {
        registerCustomProtocol(protocol);
        return;
      }
      registerCustomProtocol(protocol.scheme, protocol.optionalSlashes);
    });
  },

  onDestroy() {
    reset();
  },

  addOptions() {
    return {
      openOnClick: false,
      protocols: [],
      defaultProtocol: 'https',
      HTMLAttributes: {
        target: '_blank',
        rel: 'noopener noreferrer nofollow',
        class: '',
      },
      validate: (url) => !!url,
    };
  },

  addAttributes() {
    return {
      href: {
        default: null,
      },
      text: {
        default: null,
      },
      class: {
        default: this.options.HTMLAttributes.class,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: '.button',
        getAttrs: (node: HTMLElement) => {
          const href = node.getAttribute('href');
          const text = node.textContent;
          // prevent XSS attacks
          if (!href || !isAllowedUri(href)) return false;
          if (!text) return false;
          return { text, href };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const safetyOverrides = !isAllowedUri(HTMLAttributes.href) ? { href: '' } : {};
    return [
      'div',
      mergeAttributes(this.options.HTMLAttributes, {
        ...HTMLAttributes,
        ...safetyOverrides,
        class: ['button', HTMLAttributes.class].filter(Boolean).join(' '),
      }),
      0,
    ];
  },

  addCommands() {
    return {
      setButton:
        (attributes) =>
        ({ chain }) => {
          return chain().setNode(this.name, attributes).run();
        },
      unsetButton:
        () =>
        ({ chain }) => {
          return chain().setParagraph().run();
        },
    };
  },
});

export default ButtonExtension;

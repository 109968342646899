import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { Image } from 'cloudinary-react';
import cx from 'lib/cx';

import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { EntriesWrapper } from './CampaignPageRaffleEntries';
import RaffleTextWrapper from './CampaignPageRaffleText';

const GET_WIDGET = gql`
  query GetCampaignPageRaffle($id: String!) {
    findCampaignWidgets(id: $id) {
      id
      title
      config
    }
  }
`;

const GET_RAFFLE_DETAILS = gql`
  query GetRaffleDetails($id: String!, $raffleId: String!) {
    findCampaigns(id: $id) {
      raffles(where: { id: $raffleId }) {
        id
        name
        status
        description
        entryPackages
        amount
      }
    }
  }
`;

const CampaignPageRaffle = ({ id, className }) => {
  const { data } = useQuery(GET_WIDGET, { variables: { id } });
  const router = useRouter();
  const { id: campaignId } = router.query;
  const raffleWidget = useMemo(() => data?.findCampaignWidgets?.[0] || {}, [data]);

  const { data: raffleDetailsData } = useQuery(GET_RAFFLE_DETAILS, {
    variables: { id: campaignId, raffleId: raffleWidget?.config?.raffleId },
    skip: !raffleWidget?.config?.raffleId,
  });
  const raffleDetails = useMemo(
    () => raffleDetailsData?.findCampaigns?.[0]?.raffles?.[0] || {},
    [raffleDetailsData]
  );

  if (!raffleWidget) return null;
  return (
    <div className={cx('py-16 bg-white', className)}>
      <div className="container max-w-7xl">
        <section
          className={`flex flex-col gap-8 ${raffleWidget.config?.image ? 'md:flex-row-reverse' : 'md:flex-row'} md:justify-center md:items-center md:gap-16`}
        >
          {raffleWidget.config?.image && <ImgWrapper value={raffleWidget.config.image} />}

          <RaffleTextWrapper raffleWidget={raffleWidget} raffleDetails={raffleDetails} />

          {!raffleWidget.config?.image && <EntriesWrapper raffleDetails={raffleDetails} />}
        </section>
      </div>
    </div>
  );
};

const ImgWrapper = ({ value }) => {
  return (
    <figure className="relative overflow-hidden w-full h-[22rem] md:h-[30rem] rounded-lg">
      <Image publicId={value} width={200} alt="raffle-img" className="w-full h-full object-cover" />
    </figure>
  );
};

CampaignPageRaffle.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CampaignPageRaffle.defaultProps = {
  className: '',
};

export default CampaignPageRaffle;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import cx from 'classnames';

type EditorToolbarButtonProps = {
  icon: IconProp;
  isActive?: boolean;
  onClick: () => void;
};

const EditorToolbarButton = ({ icon, isActive = false, onClick }: EditorToolbarButtonProps) => (
  <button
    type="button"
    onMouseDown={(e) => {
      e.preventDefault();
      onClick();
    }}
    className={cx(
      'text-sm w-6 h-6 flex justify-center items-center rounded-md hover:opacity-50 transition-opacity duration-200',
      {
        'text-gray-700': !isActive,
        'bg-gray-300 text-gray-800': isActive,
      }
    )}
  >
    <FontAwesomeIcon icon={icon} size="1x" />
  </button>
);

export default EditorToolbarButton;

import PropTypes from 'prop-types';
import { useState, useMemo, useRef } from 'react';
import { gql, useQuery } from '@apollo/client';
import { motion } from 'framer-motion';
import { useIsomorphicEffect } from 'rooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';

import { useCampaignPage } from 'context/CampaignPage';
import { serializeToJSX } from 'lib/serializeTiptap';
import useBreakpoint from 'hooks/useBreakpoint';
import Button from 'components/common/Button';
import style from './CampaignStory.module.css';

const GET_CAMPAIGN = gql`
  query GetCampaign($id: String!) {
    findCampaigns(id: $id) {
      id
      campaignStory
      campaignStoryTitle
    }
  }
`;

const CampaignPageStory = ({ allowCollapse, className }) => {
  const el = useRef(null);
  const [state, setState] = useState('default');

  const mobile = !useBreakpoint('lg');
  const previewHeight = useMemo(() => (mobile ? 265 : 170), [mobile]);

  const { campaignId } = useCampaignPage();
  const { data } = useQuery(GET_CAMPAIGN, { variables: { id: campaignId } });
  const campaign = useMemo(() => data?.findCampaigns[0], [data]);

  useIsomorphicEffect(() => {
    const canCollapse = allowCollapse && (el.current?.offsetHeight ?? 0) > previewHeight + 30;
    if (canCollapse && state === 'default') {
      setState('collapsed');
    } else if (!canCollapse && state !== 'default') {
      setState('default');
    }
  }, [allowCollapse, el, previewHeight, state]);

  if (!campaign) return null;

  return (
    <div className={className}>
      <motion.div
        animate={{ height: state === 'collapsed' ? previewHeight : 'auto' }}
        className={cx('relative overflow-hidden')}
        transition={{ duration: 0.25 }}
      >
        {campaign.campaignStoryTitle && (
          <h2 className="text-2xl lg:text-3xl font-medium leading-tight mb-4">
            {campaign.campaignStoryTitle}
          </h2>
        )}
        <div ref={el} className="leading-relaxed rich-text text-base lg:text-lg">
          {serializeToJSX(campaign.campaignStory)}
        </div>
        <motion.div
          className={cx('absolute bottom-0 inset-x-0 h-24', style.scrollIndicator)}
          animate={{ opacity: state === 'collapsed' ? 1 : 0 }}
        />
      </motion.div>
      {state !== 'default' && (
        <p className="mt-2">
          <Button
            as="button"
            type="button"
            color="gray-300"
            padding="sm"
            className="mt-4 uppercase font-medium rounded-full"
            onClick={() => setState(state === 'expanded' ? 'collapsed' : 'expanded')}
            outline
          >
            {state === 'expanded' ? 'Collapse' : 'Read more'}
            <FontAwesomeIcon
              icon={state === 'expanded' ? faChevronUp : faChevronDown}
              className="ml-2"
            />
          </Button>
        </p>
      )}
    </div>
  );
};

CampaignPageStory.propTypes = {
  allowCollapse: PropTypes.bool,
  className: PropTypes.string,
};

CampaignPageStory.defaultProps = {
  allowCollapse: false,
  className: '',
};

export default CampaignPageStory;
